import React, { FunctionComponent } from 'react'
import { WidgetBase } from 'Shared/components/builders/WidgetBuilder'
import WidgetContainer from 'Shared/components/builders/WidgetContainer'

import EasySoftwareFeatures, {
  EasySoftwareFeaturesType,
} from '../EasySoftwareFeatures/EasySoftwareFeatures'

export interface WidgetEasySoftwareFeaturesType
  extends WidgetBase,
  EasySoftwareFeaturesType {
  contentType: 'EasySoftwareFeatures'
}

const WidgetEasySoftwareFeatures: FunctionComponent<WidgetEasySoftwareFeaturesType> = (
  props
) => (
  <WidgetContainer
    {...props.puxWidgetProperties}
    {...props.puxWidgetAnimation}
    {...props.metadata}
  >
    <EasySoftwareFeatures
      displayText={props.displayText}
      featuresPerex={props.featuresPerex}
      featuresList={props.featuresList}
      featuresMainButton={props.featuresMainButton}
      featuresSecondaryButton={props.featuresSecondaryButton}
    />
  </WidgetContainer>
)

export default WidgetEasySoftwareFeatures
